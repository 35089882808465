.app {
    width: 100vw;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    overflow: hidden;
}

.darkmode {
    overflow: hidden;
    filter: grayscale(1) brightness(0.8);
}

.app-top {
    overflow-y: scroll;
    overflow-x: hidden;
    width: 100%;
    height: 85vh;
    display: flex;
    /* box-shadow: rgb(63 34 98) 10px 0px 60px 0px inset,
        rgb(246 235 19) 0px 0px 0px 0px inset, rgb(42 247 206) 0px 0px 0px 0px; */
    /* box-shadow: inset 10px 0px 60px 0px rgba(50, 50, 50, 0.75);
     */
    box-shadow: inset 0px 10px 50px -3px rgba(50, 50, 50, 0.75);
}

.app-background {
    position: absolute;
    background-image: url("./graphics/bg-gradient.jpg");
    background-position: -50%;
    width: 100%;
    height: 85vh;
    z-index: -2;
    transition: all;
    transition-duration: 2s;
}

/* Background state classes:  */

.one {
    background-position: 0;
}

.two {
    background-position: 25%;
}

.three {
    background-position: 50%;
}

.four {
    background-position: 75%;
}

.five {
    background-position: 100%;
}

.one,
.two,
.three,
.four,
.five {
    transition: all;
    transition-duration: 1s;
}

.app-top::-webkit-scrollbar {
    display: none;
}

.app-top-left {
    width: 18%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
}

.app-top-middle {
    width: 64%;
    height: 100%;
}

.app-top-right {
    width: 18%;
    display: flex;
    flex-direction: row-reverse;
    overflow: hidden;
}

.app-bottom {
    position: fixed;
    bottom: 0;
    width: 100%;
    height: 15vh;
    background: linear-gradient(217deg, rgb(61, 158, 180), rgb(34, 60, 74));
    border-top: 1px solid rgb(34, 60, 74);
    border-left: 1px solid rgb(34, 60, 74);
    border-right: 1px solid rgb(34, 60, 74);
    border-image: initial;
    z-index: 3000;
}

.app-navbar {
    display: none;
}

@media screen and (max-width: 1200px) and (min-width: 601px) {
    .app-navbar {
        display: none;
    }
}

@media screen and (max-width: 600px) {
    .app {
        overflow: scroll;
    }
    .app::-webkit-scrollbar {
        display: none;
    }
    .app-navbar {
        position: relative;
        display: flex;
        width: 100vw;
        height: 7.7vh;
        justify-content: center;
        align-items: center;
        background-image: linear-gradient(
            217deg,
            rgb(63, 198, 227),
            rgb(255, 134, 164)
        );
        border-bottom: 2px solid rgb(63, 34, 98);
    }
    .app-bottom {
        display: none;
    }

    .app-top {
        height: 92vh;
        overflow: visible;
    }

    .app-top-left {
        display: none;
    }

    .app-top-right {
        display: none;
    }

    .app-top-middle {
        width: 100%;
    }

    .visible {
        display: flex;
    }

    .app-background {
        display: none;
    }
}
