.more-talents-container {
    display: flex;
    width: 90vw;
    justify-content: center;
    margin-top: 10vw;
}

.more-talents-picture-div {
    width: 100%;
    display: flex;
    justify-content: center;
}

.more-talents-picture {
    width: 26vw;
    height: 26vw;
    border-radius: 5vw;
    box-shadow: rgb(63 34 98) -1px 10px 30px 2px;
    border: 1px solid black;
}

.more-talents-name {
    width: 100%;
    color: rgb(246, 235, 19);
    text-align: center;
    font-size: 4.5vw;
    -webkit-text-stroke: 0.5px rgb(63, 34, 98);
    font-family: "Anarchie-Italic", cursive;
}

.more-talents-link {
    width: 33%;
    text-decoration: none;
}
