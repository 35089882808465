.contact-container {
    width: 100%;
    display: flex;
    justify-content: center;
    opacity: 0;
    transition: all;
    transition-duration: 0.6s;
}

.contact-container-entered {
    opacity: 1;
}

.contact-content {
    width: 83%;
    font-size: 1.4em;
    font-family: "Source Sans Pro", sans-serif;
    color: rgb(34, 60, 74);
    text-shadow: rgb(200 200 200) 0px 0px 5px;
}

#contact-first {
    margin-top: 4em;
}
#contact-last {
    margin-bottom: 0;
}

.contact-questions {
    margin-top: 0.5vw;
    margin-bottom: 1vw;
    text-align: center;
    font-size: 3.5vw;
    font-family: "THICCCBOI-ExtraBold";
    text-shadow: rgb(42 247 206) 1px 1px 3px;
}

#contact-form-container {
    display: flex;
    justify-content: center;
}

#contact-form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 50%;
}

#contact-message {
    width: 20em;
    height: 10em;
    border: 1px solid black;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 1em;
    background-image: linear-gradient(
        147deg,
        rgb(246, 235, 19),
        rgb(255, 40, 255)
    );
    box-shadow: rgb(63 34 98) 2px 2px 10px 2px;
}

#contact-message-textarea {
    width: 90%;
    height: 78%;
    border-radius: 1.2em;
    outline: none;
    background-color: pink;
    box-shadow: rgb(63 34 98) 2px 2px 10px 2px inset;
    padding: 1.2em;
    transition: all;
    transition-duration: 0.6s;
    font-size: 1rem;
}

#contact-message-textarea:hover {
    background-color: rgb(240, 151, 166);
    transition: background-color 0.5s ease;
}

#subscribe {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    width: 100%;
}
#subscribe-link {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    width: 100%;
}

#contact-send-outter {
    margin: 1.2em 0;
    border: 1px solid black;
    border-radius: 1.2em;
    width: 55%;
    height: 2em;
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: linear-gradient(
        147deg,
        rgb(255, 40, 255),
        rgb(246, 235, 19)
    );
    cursor: pointer;
}
#contact-send-outter-subscribe {
    margin: 1.2em 0;
    border: 1px solid black;
    border-radius: 1.2em;
    width: 45%;
    height: 2em;
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: linear-gradient(
        147deg,
        rgb(255, 40, 255),
        rgb(246, 235, 19)
    );
    cursor: pointer;
}

#contact-send-inner {
    border: 1px solid black;
    border-radius: 1.4em;
    width: 96%;
    height: 84%;
    background-image: linear-gradient(
        90deg,
        rgba(238, 130, 238, 0),
        pink,
        rgba(238, 130, 238, 0)
    );

    font-family: "Arturito";
    font-size: 1.2vw;
    color: rgb(34, 60, 74);
    cursor: pointer;
}
#contact-send-inner-subscribe {
    border: 1px solid black;
    border-radius: 1.4em;
    width: 96%;
    height: 84%;
    background-image: linear-gradient(
        90deg,
        rgba(238, 130, 238, 0),
        pink,
        rgba(238, 130, 238, 0)
    );

    font-family: "Arturito";
    font-size: 1.2vw;
    color: rgb(34, 60, 74);
    cursor: pointer;
}

#contact-send-inner:hover {
    background-image: linear-gradient(
        147deg,
        rgb(226, 221, 152),
        rgb(224, 160, 216)
    );
    cursor: pointer;
}
#contact-send-inner-subscribe:hover {
    background-image: linear-gradient(
        147deg,
        rgb(226, 221, 152),
        rgb(224, 160, 216)
    );
    cursor: pointer;
}

.sent {
    text-align: center;
}

/* Questions animation: */

@-webkit-keyframes questions {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
@-moz-keyframes question {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
@-o-keyframes question {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
@keyframes question {
    0% {
        opacity: 1;
    }

    75% {
        opacity: 0;
    }
}

.question-animation {
    -webkit-animation: question 1s infinite; /* Safari 4+ */
    -moz-animation: question 1s infinite; /* Fx 5+ */
    -o-animation: question 1s infinite; /* Opera 12+ */
    animation: question 1s infinite; /* IE 10+, Fx 29+ */
}

@media screen and (max-width: 1200px) and (min-width: 601px) {
    #contact-form {
        width: 12em;
    }

    #contact-message {
        width: 14em;
    }

    #contact-message-textarea {
        width: 85%;
    }
}

@media screen and (max-width: 600px) {
    .contact-container {
        background-image: linear-gradient(
            rgb(42, 247, 206),
            rgb(236, 197, 212)
        );
    }

    .contact-background {
        background-color: #2af7ce;
    }

    .contact-content {
        width: 96vw;
        text-align: justify;
        font-size: 1.1rem;
    }
    #contact-message {
        background-image: none;
        width: 78vw;
        height: 26vw;
        box-shadow: rgb(63 34 98) 2px 2px 10px 2px;
    }
    #contact-message-textarea {
        width: 70vw;
        height: 18vw;
        border: 1px dashed black;
        background-image: linear-gradient(
            90deg,
            rgba(238, 130, 238, 0),
            pink,
            rgba(238, 130, 238, 0)
        );
    }

    .contact-questions {
        font-size: 10vw;
        margin-top: 3vw;
        margin-bottom: 4vw;
    }

    #contact-send-outter {
        width: 40vw;
        height: 9vw;
        border-radius: 12vw;
    }

    #contact-send-inner {
        width: 39.5vw;
        height: 8.5vw;
        border-radius: 8vw;
        font-size: 3vw;
    }
    #contact-send-inner-subscribe {
        width: 42vw;
        height: 8.5vw;
        border-radius: 8vw;
        font-size: 3vw;
    }
}
