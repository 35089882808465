.social-container {
    border: 2px solid violet;
    position: absolute;
    width: 4vw;
    height: 4vw;
    top: 2.3vw;
    box-shadow: rgb(42 247 206) 0px 0px 10px 1px,
        rgb(42 247 206) 0px 0px 10px 1px inset;
    border-top-left-radius: 15px;
    border-bottom-left-radius: 15px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    background-image: linear-gradient(rgb(136, 184, 192), rgb(255, 255, 255));
    z-index: 1000;
    transition: all;
    transition-duration: 0.6s;
    overflow: hidden;
}

.social {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 4vw;
    height: 4vw;
    /* border: 1px solid black; */
}

.social-up {
    transform: translateY(-4vw);
    transition: all;
    transition-duration: 0.6s;
}

.social-extended {
    position: absolute;
    height: 20vw;
    transition: all;
    transition-duration: 0.6s;
}
/* .social-extended-component {
    position: absolute;
    top: 200px;
    transition: all;
    transition-duration: 0.6s;
} */

.social-ext-down {
    position: absolute;
    top: 12vw;
    transition: all;
    transition-duration: 0.6s;
}
.social-ext-up {
    position: absolute;
    top: -4vw;
    transition: all;
    transition-duration: 0.6s;
}

.fa-link-social {
    font-size: 2vw;
    text-shadow: rgb(255 40 255) 1px 1px;
}

@media screen and (max-width: 1200px) and (min-width: 601px) {
    .social-container {
        width: 6vw;
    }
    .fa-link-social {
        font-size: 3vw;
    }

    .social-extended {
        position: absolute;
        height: 26vw;
        transition: all;
        transition-duration: 0.6s;
    }
}
