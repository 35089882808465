.social-extended-container {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    z-index: 1000;
}

.social-extended-link {
    border: 3px solid black;
    border-radius: 50%;
    margin: 0 5px;
}

.social-extended-icon {
    width: 2vw;
    height: 2vw;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.4vw;
}

.social-extended-a {
    text-decoration: none;
    color: black;
}

.pink {
    background: radial-gradient(
        circle at 30% 107%,
        #fdf497 0%,
        #fdf497 5%,
        #fd5949 45%,
        #d6249f 60%,
        #285aeb 90%
    );
}

.violet {
    background-color: orchid;
}

.blue {
    background-color: #1672e6;
}

.green {
    background-color: #22c95c;
}

.cadetblue {
    background-color: #1c9bbd;
}

.apple {
    background-color: #fd445f;
}

.light-blue {
    background-color: #29ace2;
}

@media screen and (max-width: 1200px) and (min-width: 601px) {
    .social-extended-container {
        width: 6vw;
    }
    .social-extended-icon {
        width: 3vw;
        height: 3vw;
        font-size: 2vw;
    }
}

@media screen and (max-width: 600px) {
    .social-extended-container {
        height: 10%;
        width: 70%;
        position: relative;
        flex-direction: row;
        flex-wrap: wrap;
        /* align-items: flex-start; */
        /* justify-content: space-evenly; */
        border: none;
        margin-top: 0;
        box-shadow: none;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        background-image: none;
    }

    .social-extended-icon {
        width: 16vw;
        height: 16vw;
        font-size: 10vw;
    }

    .social-extended-link {
        margin-bottom: 4vw;
    }

    .pink {
        /* order: 1; */
    }

    .blue {
        order: 1;
    }

    .light-blue {
        order: 1;
    }
}
