.mobile-div {
    position: fixed;
    width: 100vw;
    height: 100vh;
    z-index: 10;
    top: 0;
    left: 0;
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    background-image: linear-gradient(
        217deg,
        rgb(34, 60, 74),
        rgb(255, 40, 255),
        rgb(252, 178, 2)
    );
    padding-top: 8vw;
}
