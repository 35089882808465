.navbar-logo-container {
    width: 15%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 7vw;
}

.navbar-name {
    width: 100%;
    font-size: 1rem;
    font-family: "Press Start 2P", cursive;
    color: rgb(42, 247, 206);
    text-shadow: rgb(63 34 98) 1px 1px;
    -webkit-text-stroke: 0.4px rgb(63, 34, 98);
    filter: drop-shadow(rgb(34, 60, 74) 1px 1px 0px);
    font-family: "Anarchie-Italic", cursive;
    font-size: 7vw;
    text-align: center;
}

.navbar-hamburger {
    position: fixed;
    top: 8vw;
    right: 4vw;
    width: 11vw;
    height: 11vw;
    z-index: 4000;
    background-color: #2af7ce;
    border-radius: 50%;
    box-shadow: rgb(63 34 98) 0px 0px 10px;
    border: 2px solid rgb(63, 34, 98);
    display: flex;
    justify-content: center;
    align-items: center;
}
