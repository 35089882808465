#add-new-artist-headline {
    margin: 40px 0;
    font-size: 2rem;
    font-family: "Press Start 2P", cursive;
    color: yellow;
    -webkit-text-stroke: 1px #49337e;
    text-align: center;
}

#add-new-artist-headline:hover {
    color: #49337e;
    -webkit-text-stroke: 1px yellow;
    transform: scale(1.1);
    transition: all;
    transition-duration: 0.6s;
}

.artists-admin-container {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.artists-admin-left {
    width: 47%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

.artists-admin-right {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.artists-par {
    font-size: 1.5vw;
    font-weight: bold;
    font-family: "Source Sans Pro", sans-serif;
    color: #49337e;
}

@media screen and (max-width: 1200px) and (min-width: 601px) {
    .artists-admin-container {
        flex-direction: column-reverse;
        align-items: center;
    }
    .artists-admin-left {
        width: 80%;
    }
    .artists-admin-right {
        width: 80%;
    }
    .artists-par {
        font-size: 3vw;
    }
}

@media screen and (max-width: 600px) {
    .artists-admin-container {
        flex-direction: column-reverse;
        align-items: center;
    }
    .artists-admin-left {
        width: 80%;
    }
    .artists-admin-right {
        width: 80%;
    }
    .artists-par {
        font-size: 6vw;
    }
}
