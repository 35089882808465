.feed-item {
    border: 2px solid blue;
    width: 97%;
    padding: 1vw 0;
    margin-bottom: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    border: 2px solid rgb(34, 60, 74);
    box-shadow: rgb(63 34 98) -1px 3px 20px;
    border-radius: 15px;
    background-image: linear-gradient(
        45deg,
        rgb(240, 134, 85),
        rgb(239, 89, 151)
    );
}

.feed-image-div {
    width: 96%;

    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 15px;
}

.feed-image {
    width: 100%;
    border: 1px solid rgb(63, 34, 98);
    border-radius: 15px;
    cursor: pointer;
}

.feed-title {
    position: absolute;
    top: 2vw;
    left: 2vw;
    max-width: 90%;
    border: 1px solid rgb(63, 34, 98);
    border-radius: 10vw;
    background-color: rgb(136, 184, 192);
    text-shadow: rgb(252 178 2) 2px 2px;
    color: rgb(63, 34, 98);
}
.feed-social {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    bottom: 2vw;
    left: 2vw;
    padding: 10px 15px;
    border: 1px solid rgb(63, 34, 98);
    border-radius: 10vw;
    background-color: rgb(136, 184, 192);
    color: rgb(63, 34, 98);
    z-index: 3;
}

.feed-title-h {
    margin: 15px 15px;
    font-family: "Anarchie-Italic", cursive;
    font-size: 2vw;
}

/* Styling of feed items with text */

.feed-item-with-text {
    border: 2px solid blue;
    width: 97%;
    margin-bottom: 30px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    position: relative;
    border: 2px solid rgb(34, 60, 74);
    box-shadow: rgb(63 34 98) -1px 3px 20px;
    border-radius: 15px;
    background-image: linear-gradient(
        45deg,
        rgb(240, 134, 85),
        rgb(239, 89, 151)
    );
}

.feed-item-with-text-container {
    display: flex;
    flex-direction: row;
    width: 98%;
    justify-content: center;
}

.feed-item-with-text-photo-container {
    width: 48%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    padding: 1vw;
}

.feed-item-with-text-photo {
    border-radius: 15px;
    border: 1px solid rgb(63, 34, 98);
    width: 100%;
    cursor: pointer;
    /* height: 94%; */
}

.feed-title-with-text {
    padding: 1vw;
    margin-bottom: 0;
    margin-top: 1vw;
    margin-left: 3vw;
    font-family: "Anarchie-Italic", cursive;
    font-size: 2vw;
    text-shadow: rgb(252 178 2) 2px 2px;
    color: rgb(63, 34, 98);
    text-align: left;
    width: 100%;
}

.feed-text-with-text {
    width: 100%;
    min-height: 24.8vw;
    color: rgb(63, 34, 98);
    font-family: "Source Sans Pro", sans-serif;
}

.feed-with-text-social {
    display: flex;
    justify-content: flex-start;
    padding: 1vw;
}

.feed-social-with-text {
    padding-top: 1.5vw;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
}

.feed-item-content {
    margin: 1vw;
    width: 48%;
}
.read-more {
    margin: 0;
    margin-top: 1vw;
    height: 2vh;
    font-weight: bold;
    display: block;
    color: rgb(63, 34, 98);
    font-family: "Source Sans Pro", sans-serif;
    cursor: pointer;
}

@media screen and (max-width: 1200px) and (min-width: 601px) {
    .feed-title {
        top: 4vw;
        left: 4vh;
    }
    .feed-social {
        bottom: 4vh;
        left: 4vh;
    }
    .feed-title-h {
        font-size: 3vw;
    }
}

@media screen and (max-width: 600px) {
    .feed-item {
        width: 90%;
        display: none;
    }
    .feed-title {
        top: 3vh;
        left: 3vh;
    }
    .feed-social {
        bottom: 3vh;
        left: 3vh;
    }

    .feed-title-h {
        font-size: 4vw;
    }

    /* Feed with text styling */
    .feed-item-with-text {
        display: none;
        flex-direction: column-reverse;
        width: 90%;
    }

    .feed-item-with-text-container {
        width: 94%;
    }
    .feed-item-with-text-photo-container {
        width: 94%;
        margin-right: 0;
    }
    .feed-title-with-text {
        font-size: 8vw;
        margin-top: 2vw;
        margin-bottom: 2vw;
    }
}
