.label-maxi-container {
    display: flex;
    margin-top: 2.3vw;
}

.label-container {
    display: none;
}

.label {
    position: absolute;
    width: 76vw;
    height: 77vh;
    left: 17.5vw;
    padding-right: 1vw;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px yellow solid;
    border-radius: 15px;
    box-shadow: rgb(34 60 74) 1px 1px 20px 5px;
    overflow: hidden;
    transform: translateX(-100%);
    opacity: 0;
    transition: all;
    transition-duration: 0.6s;
}

.label-entered {
    transform: translateX(0);
    opacity: 1;
    transition: all;
    transition-duration: 0.6s;
}

.label-left {
    width: 50%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

.label-left-first {
    position: relative;
    border-radius: 15px;
    width: 98%;
    height: 76%;
    margin-top: 3vw;
    overflow: hidden;
    border: 1px solid black;
}

.label-text {
    text-align: justify;
}
.button-yellow {
    color: #ffff00;
}

.label-dots {
    position: absolute;
    bottom: 1vw;
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
}
.label-dot {
    width: 1.6vw;
    height: 1.6vw;
    margin: 0 1vw;

    border: 1px solid black;
    background-color: #8ae9ff;
    border-radius: 0.5vw;
}
.label-dot-active {
    width: 1.6vw;
    height: 1.6vw;
    margin: 0 1vw;
    border: 1px solid black;
    background-color: #f6eb13;
    border-radius: 0.5vw;
}

.label-picture-image-div {
    max-width: 100%;
    max-height: 90%;
    display: flex;
    justify-content: center;
    align-items: center;
    /* overflow: hidden; */
}
.label-image-div {
    width: 100%;
    height: 100%;
    /* display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden; */
    /* display: cover; */
}
.label-image {
    width: 100%;
    height: 100%;
    cursor: pointer;
    object-fit: cover;
    /* object-fit: cover; */
}

.label-picture-image {
    position: relative;
    /* width: 100%;
    height: 100%; */
    max-width: 60%;
    max-height: 80%;
    z-index: 100000;
    cursor: pointer;
}

.label-right {
    width: 50%;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.label-right-first {
    height: 95%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    padding-left: 0.4vw;
}

.label-name {
    font-size: 2.5vw;
    font-family: "Anarchie-Italic", cursive;
    color: rgb(252, 178, 2);
    -webkit-text-stroke: 1px rgb(63, 34, 98);
    text-shadow: rgb(42 247 206) 2px 2px 1px;
    font-weight: lighter;
    text-align: start;
    margin-top: 1vw;
    margin-bottom: 0;
}

.button-text {
    align-self: center;
}

@media screen and (max-width: 1200px) and (min-width: 601px) {
    .label {
        flex-direction: column;
        height: 100%;
        padding-top: 2vw;
        position: relative;
        top: 0;
        left: 0;
        margin-bottom: 3vw;
    }
}

@media screen and (max-width: 600px) {
    .label-maxi-container {
        display: none;
    }

    .label-container {
        display: block;
        background-color: #92aac8;
        min-height: 100vh;
        display: flex;
        flex-direction: column;
        /* justify-content: center; */
        align-items: center;
        padding: 0 2vw;
    }

    .label-name {
        font-size: 12vw;
    }

    .label-button {
        border: none;
        background: none;
        margin-top: 1vw;
        font-size: 10vw;
        font-family: "Anarchie-Italic", cursive;
        /* color: rgb(136, 184, 192); */
        -webkit-text-stroke: 1px rgb(63, 34, 98);
        text-shadow: rgb(252 178 2) 2px 2px 0px;
        text-decoration: none;
        margin-bottom: 30px;
        cursor: pointer;
    }

    .label-left-first {
        width: 85%;
        /* height: 85%; */
    }

    .label-dot {
        width: 6vw;
        height: 6vw;
        margin: 0 1vw;

        border: 1px solid black;
        background-color: #8ae9ff;
        border-radius: 2vw;
    }
    .label-dot-active {
        width: 6vw;
        height: 6vw;
        margin: 0 1vw;
        border: 1px solid black;
        background-color: #f6eb13;
        border-radius: 2vw;
    }

    .label-dots {
        bottom: 3vw;
    }

    .label-links {
        width: 65%;
        display: flex;
        justify-content: space-evenly;
    }
}
