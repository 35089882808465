.feed-mini-mobile {
    display: none;
}

@media screen and (max-width: 1200px) and (min-width: 601px) {
}

@media screen and (max-width: 600px) {
    .feed-mini-mobile {
        width: 94vw;
        display: block;
    }

    .feed-mini-mobile-title {
        font-family: "Anarchie-Italic", cursive;
        font-size: 7vw;
        color: rgb(63, 34, 98);
        text-shadow: rgb(136 184 192) 2px 2px;
        margin-top: 0;
    }

    .feed-mini-mobile-image-container {
        width: 100%;
    }
    .feed-mini-mobile-image {
        width: 100%;
        border: 1px solid black;
        border-radius: 10px;
    }

    .feed-mini-mobile-social {
        display: flex;
        justify-content: flex-start;
        margin: 8vw 0;
    }

    .feed-mini-mobile-hr {
        color: black;
        background-color: black;
        border: 0;
        width: 100%;
        height: 1px;
        margin-bottom: 2vw;
    }

    .feed-mini-text {
        width: 100%;
        color: rgb(63, 34, 98);
        font-family: "Source Sans Pro", sans-serif;
        font-size: 5vw;
        display: inline;
    }

    .read-more {
        display: flex;
        cursor: pointer;

        justify-content: flex-start;
        align-items: center;
        margin: 8vw 0;
        font-size: 6vw;
    }
}
