.talents-picture-container {
    width: 94vw;
    border-radius: 6vw;
    height: 90vw;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    box-shadow: rgb(63 34 98) -1px 10px 30px 2px;
}

.talents-picture-image {
    width: 100%;
}

.talents-picture-name {
    color: rgb(246, 235, 19);
    text-align: center;
    font-size: 8vw;
    -webkit-text-stroke: 0.5px rgb(63, 34, 98);
    font-family: "Anarchie-Italic", cursive;
}
