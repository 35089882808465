.new-artist-form {
    width: 90%;
    background-color: white;
    border: 2px solid #49337e;
    border-radius: 30px;
    padding: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.new-artist-form-section {
    width: 100%;
    display: flex;
    align-items: center;
    margin: 6px;
}

.new-artist-label-div {
    width: 25%;
}

.new-artist-input-div {
    width: 75%;
}
.new-artist-label {
    width: 90%;
    display: flex;
    justify-content: end;
    font-size: 1.2vw;
    font-family: "Source Sans Pro", sans-serif;
    color: #49337e;
    font-weight: bold;
}

.new-artist-input {
    width: 85%;
    height: 30px;
    font-size: 1rem;
    border-radius: 30px;
    outline: none;
    padding: 0 6px;
    border: 2px solid #49337e;
    color: #49337e;
    font-family: "Source Sans Pro", sans-serif;
    padding: 0 15px;
}

.new-artist-submit {
    display: flex;
    justify-content: center;
    margin-top: 15px;
}

.new-artist-submit-button {
    width: 100px;
    height: 30px;
    border-radius: 30px;
    border: 2px solid #49337e;
    background-color: white;
    color: #49337e;
    font-weight: bold;
    font-family: "Source Sans Pro", sans-serif;
    outline: none;
    cursor: pointer;
}

.new-artist-submit-button:hover {
    color: white;
    background-color: #49337e;
    transform: scale(1.2);
    transition: all;
    transition-duration: 0.6s;
}

.new-artist-file {
    display: none;
}

.new-artist-upload-btn {
    height: 30px;
    border-radius: 30px;
    border: 2px solid #49337e;
    background-color: white;
    color: #49337e;
    font-family: "Source Sans Pro", sans-serif;
    font-size: 1rem;
    padding: 0 15px;
    outline: none;
}

.new-artist-upload-btn:hover {
    color: white;
    background-color: #49337e;
    transform: scale(1.2);
    transition: all;
    transition-duration: 0.6s;
}

.new-artist-file-button-div {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

#file-link-name {
    margin: 0 15px;
    width: 100%;
    font-family: "Source Sans Pro", sans-serif;
    color: #49337e;
    font-size: 1rem;
}

.textarea {
    width: 18.5vw;
    height: 20vw;
    overflow: hidden;
    padding-top: 1vw;
    /* padding-bottom: 2vw; */
}

@media screen and (max-width: 1200px) and (min-width: 601px) {
    .new-artist-form {
        width: 90%;
    }
    .new-artist-label {
        font-size: 2.5vw;
    }
    .new-artist-label-div {
        width: 35%;
    }

    .new-artist-input-div {
        width: 75%;
    }
    .textarea {
        height: 30vw;
        width: 27vw;
    }
}

@media screen and (max-width: 600px) {
    .new-artist-form {
        width: 100%;
    }
    .new-artist-label {
        font-size: 3vw;
    }
    .new-artist-input {
        font-size: 0.7rem;
    }
    #file-link-name {
        font-size: 0.7rem;
    }
    .textarea {
        height: 60vw;
        width: 51.5vw;
    }
}
