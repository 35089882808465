.email-popup-container {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 85vh;
    z-index: 5;
    display: flex;
    justify-content: center;
    align-items: center;
}

.email-popup-box {
    width: 35vw;
    height: 12vw;

    background-image: linear-gradient(rgb(136, 184, 192), rgb(255, 255, 255));

    border-radius: 1vw;
    border: 1px solid rgb(63, 34, 98);
    display: flex;
    justify-content: center;
}

.email-popup-content {
    width: 70%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.email-popup-message {
    font-size: 1.3vw;
    color: rgb(63, 34, 98);
    font-weight: bold;
    text-align: center;
}

#email-popup-button-outter {
    margin: 1.2em 0;
    border: 1px solid black;
    border-radius: 1.6em;
    width: 50%;
    height: 3em;
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: linear-gradient(
        147deg,
        rgb(255, 40, 255),
        rgb(246, 235, 19)
    );
}

#email-popup-button-inner {
    border: 1px solid black;
    border-radius: 1.4em;
    width: 92%;
    height: 80%;
    background-image: linear-gradient(
        90deg,
        rgba(238, 130, 238, 0),
        pink,
        rgba(238, 130, 238, 0)
    );
    text-align: center;
    font-size: 1vw;
    font-weight: bold;
    outline: none;
}

#email-popup-button-inner:hover {
    background-image: linear-gradient(
        147deg,
        rgb(226, 221, 152),
        rgb(224, 160, 216)
    );
}

@media screen and (max-width: 1200px) and (min-width: 601px) {
    .email-popup-box {
        width: 55vw;
        height: 22vw;
    }
    .email-popup-message {
        font-size: 2.5vw;
    }
    #email-popup-button-outter {
        width: 20vw;
        height: 5vw;
        border-radius: 10vw;
    }
    #email-popup-button-inner {
        width: 18.5vw;
        height: 4vw;
        border-radius: 12vw;
        font-size: 1.5vw;
    }
}

@media screen and (max-width: 600px) {
    .email-popup-container {
        height: 100vh;
    }
    .email-popup-box {
        width: 80vw;
        height: 30vw;
    }
    .email-popup-message {
        font-size: 3.5vw;
    }
    #email-popup-button-outter {
        width: 24vw;
        height: 6vw;
        border-radius: 10vw;
    }
    #email-popup-button-inner {
        width: 22vw;
        height: 4vw;
        border-radius: 12vw;
        font-size: 2vw;
    }
}
