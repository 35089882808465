.logo-div {
    position: fixed;
    width: 22vw;
    top: -1vw;
    left: -2vw;
    z-index: 1000;
}

#logo-image {
    width: 100%;
}

@media screen and (max-width: 1200px) and (min-width: 601px) {
}

@media screen and (max-width: 600px) {
    .logo-div {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 8vh;
        z-index: 5;
    }

    .logo-link {
        height: 100%;
    }

    #logo-image {
        /* width: 120px; */
        margin-top: 1.5vw;
        height: 4.5vh;
        filter: drop-shadow(rgb(34, 60, 74) 0px 0px 10px);
        transform: translateX(-3vw);
    }
}
