.talents-container {
    width: 100%;
    min-height: 85vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    opacity: 0;
    transition: all;
    transition-duration: 0.6s;
}

.talents-container-entered {
    opacity: 1;
}

.talents-mobile-container {
    display: none;
}

.artists-container {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    width: 100%;
    position: relative;
}

.talents-headline {
    /* margin-top: 5vw; */
    width: 40vw;
    margin-bottom: 1vw;
}

.test {
    opacity: 0;
    transition: all;
}

.test-visible {
    opacity: 1;
    transition: all;
}

.test-visible {
    /* all the needed stuff here */
    transition-duration: 0.4s;
    transition-delay: 0.3s;
}

.test-visible:nth-child(2) {
    transition-delay: 0.5s;
}

.test-visible:nth-child(3) {
    transition-delay: 0.7s;
}

.test-visible:nth-child(4) {
    transition-delay: 0.9s;
}
.test-visible:nth-child(5) {
    transition-delay: 1.1s;
}
.test-visible:nth-child(6) {
    transition-delay: 1.3s;
}
.test-visible:nth-child(7) {
    transition-delay: 1.5s;
}

/* Vibe animation: */

@-webkit-keyframes vibe {
    0% {
        transform: translateX(-0.8vw);
    }
    25% {
        transform: translateX(0.6vw);
    }
    50% {
        transform: translateX(-0.8vw);
    }
    75% {
        transform: translateX(0.05);
    }

    100% {
        transform: translateX(0);
    }
}
@-moz-keyframes vibe {
    0% {
        transform: translateX(-0.8vw);
    }
    25% {
        transform: translateX(0.6vw);
    }
    50% {
        transform: translateX(-0.8vw);
    }
    75% {
        transform: translateX(0.05);
    }

    100% {
        transform: translateX(0);
    }
}
@-o-keyframes vibe {
    0% {
        transform: translateX(-0.8vw);
    }
    25% {
        transform: translateX(0.6vw);
    }
    50% {
        transform: translateX(-0.8vw);
    }
    75% {
        transform: translateX(0.05);
    }

    100% {
        transform: translateX(0);
    }
}
@keyframes vibe {
    0% {
        transform: translateX(-0.8vw);
    }
    25% {
        transform: translateX(0.6vw);
    }
    50% {
        transform: translateX(-0.8vw);
    }
    75% {
        transform: translateX(0.05);
    }

    100% {
        transform: translateX(0);
    }
}

.vibe-animation {
    -webkit-animation: vibe 5s infinite; /* Safari 4+ */
    -moz-animation: vibe 5s infinite; /* Fx 5+ */
    -o-animation: vibe 5s infinite; /* Opera 12+ */
    animation: vibe 1s infinite; /* IE 10+, Fx 29+ */
}

@media screen and (max-width: 1200px) and (min-width: 601px) {
    .talents-headline {
        width: 50vw;
    }
}

@media screen and (max-width: 600px) {
    .talents-container {
        display: none;
    }

    .talents-mobile-container {
        background-image: linear-gradient(rgb(34, 60, 74), rgb(255, 40, 255));
        display: flex;
        min-height: 100%;
        flex-direction: column;
        align-items: center;
    }

    .talents-headline {
        width: 100vw;
        margin-top: 4vw;
    }

    .talents-mobile-navigation {
        display: flex;
        width: 100%;
        justify-content: space-around;
        align-items: center;
        margin-bottom: 5vw;
    }
    .talents-dots {
        display: flex;
        width: 25%;
        justify-content: space-between;
    }
    .talents-dot {
        width: 4vw;
        height: 4vw;
        border: 1px solid black;
        background-color: #8ae9ff;
        border-radius: 1vw;
    }
    .talents-dot-active {
        width: 4vw;
        height: 4vw;
        border: 1px solid black;
        background-color: #f6eb13;
        border-radius: 1vw;
    }

    .talents-nav-button {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 23vw;
        height: 12vw;
        background-color: #8ae9ff;
        border-radius: 5vw;
        color: rgb(63, 34, 98);
        border: 1px solid rgb(63, 34, 98);
        box-shadow: rgb(63 34 98) -1px 10px 30px 2px;
    }

    .talents-link {
        text-decoration: none;
    }
}
