.artist-mini {
    width: 15vw;
    height: 15vw;
    position: relative;
    margin-bottom: 60px;
    border-radius: 25%;
    box-shadow: rgb(63 34 98) 0px 5px 60px 0px;
    transition: all;
    transition-duration: 0.6s;
    background-color: transparent;
}
.artist-mini-picture {
    border: 1px solid #04216a;
    border-radius: 25%;
    width: 100%;
    height: 100%;
}

.artist-mini:hover {
    box-shadow: none;
    transition: all;
    transition-duration: 0.6s;
    filter: hue-rotate(90deg);
}

.artist-mini-name {
    width: 100%;
    position: absolute;
    text-align: center;
    bottom: 0.1vw;
    font-size: 1.5em;
    font-family: "Anarchie-Italic", cursive;
    color: yellow;
}

@media screen and (max-width: 1200px) and (min-width: 601px) {
    .artist-mini {
        width: 28vw;
        height: 28vw;
    }
}

@media screen and (max-width: 600px) {
    .artist-mini {
        width: 60vw;
        height: 60vw;
    }
}
