.fa-circle-play {
    margin-right: 0.5vw;
}
.fa-circle-pause {
    margin-right: 0.8vw;
}
.fa-volume-high {
    margin-left: 0.5vw;
}

.track-playing {
    font-weight: 600;
}
