.booknow-container {
    padding-top: 2.3vw;
    width: 100%;
    min-height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    transform: translateX(-100%);
    opacity: 0;
    transition: all;
    transition-duration: 0.6s;
}

.booknow-container-entered {
    transform: translateX(0);
    opacity: 1;
}

.booknow-form-div {
    width: 70%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.booknow-item {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
    border: 1px solid rgb(63, 34, 98);
    border-radius: 12px;
    background-image: linear-gradient(
        70deg,
        rgb(61, 158, 180),
        transparent,
        rgb(33, 151, 165)
    );
    box-shadow: rgb(63 34 98) 2px 2px 15px 2px inset,
        rgb(63 34 98) 2px 2px 50px 5px;
}

.booknow-section {
    width: 80%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.booknow-headline {
    width: 100%;
    color: rgb(63, 34, 98);
    font-family: "Source Sans Pro", sans-serif;
    font-weight: 400;
    text-align: start;
    margin: 3vw 0;
}

.booknow-line {
    margin-bottom: 2.6vw;
    position: relative;
    display: flex;
    flex-direction: row-reverse;
    border: 1px dashed black;
    width: 100%;
    height: 2.2em;
    border-radius: 12vw;
    align-items: center;
    padding: 0 0.2em;
    background-image: linear-gradient(
        147deg,
        rgb(246, 235, 19),
        rgb(255, 40, 255)
    );
    box-shadow: rgb(63 34 98) 2px 2px 10px 2px;
}

.booknow-input {
    text-align: center;
    width: 55%;
    padding: 0 1.2em;
    height: 80%;
    border: none;
    outline: none;
    border: 1px dashed black;
    border-radius: 1.4em;
    right: 0.3em;
    background-image: linear-gradient(
        90deg,
        rgba(238, 130, 238, 0),
        pink,
        rgba(238, 130, 238, 0)
    );
    transition: all;
    transition-duration: 0.6s;
    font-family: "Arturito";
    font-size: 1rem;
}

.booknow-input-date {
    text-align: center;
    width: 55%;
    padding: 0 1.2em;
    height: 80%;
    border: none;
    outline: none;
    border: 1px dashed black;
    border-radius: 15vw;
    font-family: "Arturito";
    font-size: 1rem;

    right: 0.3em;
    background-image: linear-gradient(
        90deg,
        rgba(238, 130, 238, 0),
        pink,
        rgba(238, 130, 238, 0)
    );
    transition: all;
    transition-duration: 0.6s;
}

.booknow-input-country {
    font-family: "Arturito";
    font-size: 1rem;

    text-align: center;
    width: 62%;
    padding: 0 1.2em;
    height: 86%;
    border: none;
    outline: none;
    border: 1px dashed black;
    border-radius: 15vw;
    right: 0.3em;
    background-image: linear-gradient(
        90deg,
        rgba(238, 130, 238, 0),
        pink,
        rgba(238, 130, 238, 0)
    );
    transition: all;
    transition-duration: 0.6s;
}

.booknow-input-extended {
    font-family: "Arturito";
    font-size: 1rem;

    text-align: center;
    width: 55%;
    padding: 0 1.2em;
    height: 80%;
    border: none;
    outline: none;
    border: 1px dashed black;
    border-radius: 1.4em;
    right: 0.3em;
    background-image: linear-gradient(
        90deg,
        rgba(238, 130, 238, 0),
        pink,
        rgba(238, 130, 238, 0)
    );
    transition: all;
    transition-duration: 0.6s;
}

.booknow-input:hover {
    padding-left: 10vw;
    width: 100%;
}

.booknow-select {
    /* padding: 0.4%; */
    text-align: center;
    width: 62%;
    font-size: 1rem;

    padding: 0 1.2em;
    height: 86%;
    border: none;
    outline: none;
    border: 1px dashed black;
    border-radius: 1.4em;
    right: 0.3em;
    font-family: "Arturito";

    background-image: linear-gradient(
        90deg,
        rgba(238, 130, 238, 0),
        pink,
        rgba(238, 130, 238, 0)
    );
    transition: all;
    transition-duration: 0.6s;
}

.booknow-label {
    position: absolute;
    z-index: 2;
    left: 1em;
    font-family: "Anarchie-Italic", cursive;
    font-size: 1.3vw;
    text-shadow: rgb(42 247 206) 1px 1px 0px;
}

#booknow-send-outter {
    margin: 1.2em 0;
    margin-bottom: 2vw;
    border: 1px solid black;
    border-radius: 10vw;
    width: 14vw;
    height: 3vw;
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: linear-gradient(
        147deg,
        rgb(255, 40, 255),
        rgb(246, 235, 19)
    );
    cursor: pointer;
}

#booknow-send-inner {
    border: 1px solid black;
    border-radius: 12vw;
    width: 98%;
    height: 90%;
    background-image: linear-gradient(
        90deg,
        rgba(238, 130, 238, 0),
        pink,
        rgba(238, 130, 238, 0)
    );
    font-family: "Arturito";
    font-size: 1.3vw;
    color: rgb(34, 60, 74);
    cursor: pointer;
}

#booknow-send-inner:hover {
    background-image: linear-gradient(
        147deg,
        rgb(226, 221, 152),
        rgb(224, 160, 216)
    );
}

.booknow-content {
    display: flex;
    justify-content: center;
    width: 85%;
    font-size: 1.4em;
    color: rgb(34, 60, 74);
    text-shadow: rgb(200 200 200) 0px 0px 5px;
    cursor: pointer;
}

#booknow-first {
    margin-top: 4em;
}

.booknow-sent {
    text-align: center;
}
@media screen and (max-width: 1200px) and (min-width: 601px) {
    .booknow-form-div {
        width: 95%;
    }

    .booknow-section {
        width: 85%;
    }
    .booknow-input {
        width: 55%;
        font-size: 1.5vw;
    }

    .booknow-input:hover {
        padding-left: 16vw;
    }

    .booknow-input-date {
    }

    .booknow-input-country {
        font-size: 1.5vw;
    }
    .booknow-input-extended {
        font-size: 1.5vw;
    }
    .booknow-input-date {
        font-size: 1.5vw;
    }
    .booknow-select {
        border-radius: 15vw;
        font-size: 1.5vw;
    }

    .booknow-label {
        font-size: 2vw;
    }
}

@media screen and (max-width: 600px) {
    .booknow-background {
        background-image: linear-gradient(rgb(61, 158, 180), rgb(63, 34, 98));
    }
    .booknow-container {
        background-image: linear-gradient(rgb(61, 158, 180), rgb(63, 34, 98));
        padding-top: 7vw;
    }

    .booknow-item {
        background-image: none;
        border: none;
        box-shadow: none;
    }
    .booknow-form-div {
        width: 100%;
    }
    .booknow-input {
        width: 50%;
        font-size: 0.7rem;
    }
    .booknow-label {
        font-size: 5vw;
    }

    .booknow-headline {
        font-size: 3vw;
    }
    .booknow-line {
        height: 8vw;
        margin: 1vw 0;
    }

    .booknow-input {
        width: 35vw;
        height: 6.5vw;
        border-radius: 10vw;
        font-size: 3vw;
    }

    .booknow-input:hover {
        padding-left: 36vw;
    }

    .booknow-input-extended {
        width: 40vw;
        height: 7vw;
        font-size: 3vw;
    }
    .booknow-input-country {
        width: 43vw;
        height: 7vw;
        font-size: 3vw;
    }

    .booknow-select {
        width: 43vw;
        height: 7vw;
        border-radius: 10vw;
        font-size: 3vw;
    }

    .booknow-input-date {
        width: 35vw;
        height: 6.5vw;
        font-size: 3vw;
    }

    #booknow-send-outter {
        width: 40vw;
        height: 8vw;
        margin-bottom: 8vw;
    }
    #booknow-send-inner {
        width: 39vw;
        height: 7vw;
        font-size: 3vw;
    }
}
