.artist-link {
    border: 3px solid black;
    width: 2.2vw;
    height: 2.2vw;
    border-radius: 50%;
    margin: 0 0.5vw;
    display: flex;
    justify-content: center;
    align-items: center;
}

.artist-icon {
    font-size: 1.4vw;
}

.artist-icon-facebook {
    color: white;
    font-weight: bold;
}

.a {
    text-decoration: none;
    color: black;
    display: flex;
    justify-content: center;
    align-items: center;
}

.ra,
.dc,
.ap {
    width: 1.8vw;
    height: 1.8vw;
}

.orange {
    background-color: orangered;
}
.purple {
    background-image: linear-gradient(to bottom right, purple, yellow);
}
.blue {
    background-color: #1672e6;
}

.white {
    background-color: white;
}
.red {
    background-color: red;
}

.cadetblue {
    background-color: #1c9bbd;
}

.chartreuse {
    background-color: rgb(182, 202, 108);
}

.pink {
    background: radial-gradient(
        circle at 30% 107%,
        #fdf497 0%,
        #fdf497 5%,
        #fd5949 45%,
        #d6249f 60%,
        #285aeb 90%
    );
}

.color {
    background-color: #22c95c;
}

.lime {
    background-color: #f5df2e;
}

.grey {
    background-color: grey;
}

.insta-big {
    font-weight: bold;
}

.apple {
    background-color: #fd445f;
}

@media screen and (max-width: 1200px) and (min-width: 601px) {
    .artist-link {
        border: 1px solid black;
        width: 3vw;
        height: 3vw;
        margin: 0 0.6vw;
    }

    .artist-icon {
        font-size: 2vw;
    }

    .ra,
    .dc,
    .ap {
        width: 2vw;
        height: 2vw;
    }
}

@media screen and (max-width: 600px) {
    .a {
        width: 11.75vw;
        height: 11.75vw;
        /* margin: 0; */
    }
    .artist-link {
        margin: 0;
        width: 9.5vw;
        height: 9.5vw;
    }

    .artist-icon {
        font-size: 6.5vw;
    }

    .ra,
    .dc,
    .ap {
        width: 8vw;
        height: 8vw;
    }

    .label-links {
        display: flex;
        /* width: 40%; */
        justify-content: center;
        margin-bottom: 12vw;
    }
}
