.artists-maxi-container {
    display: flex;
    margin-top: 2.3vw;
}

.artists {
    position: absolute;
    width: 76vw;
    height: 77vh;
    left: 17.5vw;
    padding-right: 1vw;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px yellow solid;
    border-radius: 15px;
    box-shadow: rgb(34 60 74) 1px 1px 20px 5px;
    overflow: hidden;
    transform: translateX(-100%);
    opacity: 0;
    transition: all;
    transition-duration: 0.6s;
}

.artists-entered {
    transform: translateX(0);
    opacity: 1;
    transition: all;
    transition-duration: 0.6s;
}

.left {
    width: 50%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

.right {
    width: 50%;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.left-first {
    border-radius: 15px;
    width: 98%;
    height: 76%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 3vw;
    overflow: hidden;
    border: 1px solid black;
}

.left-second {
    display: flex;
    margin-top: 2vw;
    position: absolute;
    bottom: 1.5vw;
}
.left-third {
    display: flex;
    margin-top: 2vw;
    margin-bottom: 1.5vw;
    display: none;
    /* padding-top: 1vw; */
}
.right-first {
    height: 95%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    padding-left: 0.4vw;
}

.right-second {
    display: flex;
    margin-bottom: 1.5vw;
    width: 100%;
    height: 5%;
    justify-content: space-between;
    align-items: center;
}

.artist-image {
    width: 100%;
    height: 100%;
    cursor: pointer;
    object-fit: cover;
}

.artist-name {
    font-size: 4.5vw;
    font-family: "Anarchie-Italic", cursive;
    color: rgb(252, 178, 2);
    -webkit-text-stroke: 1px rgb(63, 34, 98);
    text-shadow: rgb(42 247 206) 2px 2px 1px;
    font-weight: lighter;
    text-align: start;
    margin-top: 1vw;
    margin-bottom: 0;
}

.fa-chevron-circle-left {
    font-size: 1.4vw;
    margin-right: 5px;
}

.fa-arrow-circle-right {
    font-size: 1.4vw;
    margin-left: 5px;
}

#back {
    font-size: 1.4vw;
    font-weight: bold;
}
#book {
    font-size: 1.4vw;
    font-weight: bold;
}

.book-button-div {
    border: 1px solid black;
    width: 11vw;
    height: 2.6vw;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2vw;
    border-radius: 25px;
    background: linear-gradient(147deg, rgb(136, 184, 192), rgb(255, 255, 255));
    font-family: "Arturito";
}

.back-button {
    height: 2.6vw;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2vw;
    font-family: "Arturito";
}

.artist-description {
    color: rgb(63, 34, 98);
    text-align: justify;
    text-justify: auto;
    font-size: 1.1vw;
    font-family: "Source Sans Pro", sans-serif;
}

.artist-performance {
    color: rgb(63, 34, 98);
    font-size: 1rem;
    margin-bottom: 0;
}

.artist-maxi-more-container {
    display: none;
}

/* Transition effects: */
.fade {
    transition: all;
    transition-duration: 0.6s;
    opacity: 0;
}

.fade-entering {
    transform: scale(1);
    opacity: 1;
    transition: all;
    transition-duration: 0.6s;
}
.fade-entered {
    opacity: 1;
    transform: scale(1);
    transition: all;
}
.fade-exiting {
    transform: scale(4.4);
    transition: all;
    transition-duration: 0.6s;
    opacity: 0;
}
.fade-exited {
    transform: scale(4.4);
    transition: all;
    opacity: 0;
    visibility: hidden;
    transition-duration: 0.6s;
    z-index: -5;
}

.talent-picture-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 85vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: radial-gradient(
        rgb(236, 197, 212),
        rgb(61, 158, 180),
        rgb(144, 69, 254)
    );
    z-index: 50;
}

.talent-picture-image-div {
    max-width: 100%;
    max-height: 90%;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}

.talent-picture-image {
    position: relative;
    /* width: 100%;
    height: 100%; */
    max-width: 60%;
    max-height: 80%;
    z-index: 100000;
    cursor: pointer;
}

.talent-picture-x {
    align-self: flex-start;
    padding-left: 1vw;
    font-size: 3vw;
    color: rgb(63, 34, 98);
    transition: all;
    transition-duration: 0.6s;
    cursor: pointer;
}

.talent-picture-x:hover {
    color: yellow;
}

.link-back {
    text-decoration: none;
    position: absolute;
    bottom: 1.5vw;
}
.link-book {
    text-decoration: none;
    position: absolute;
    bottom: 1.5vw;
    right: 0.5vw;
}

.artist-image-mobile {
    display: none;
}

@media screen and (max-width: 1200px) and (min-width: 601px) {
    .artists {
        flex-direction: column;
        height: 100%;

        padding-top: 2vw;
        position: relative;
        top: 0;
        left: 0;
    }

    .left-first {
        border: none;
    }
    .left,
    .right {
        width: 100%;
    }

    .artist-image {
        width: 60%;
    }

    .artist-name {
        text-align: center;
        font-size: 3vw;
    }

    #back,
    #book,
    .fa-chevron-circle-left,
    .fa-arrow-circle-right {
        font-size: 1.6em;
    }

    .book-button-div {
        width: 16vw;
        height: 4vw;
        font-size: 1.8vw;
    }
    .back-button {
        height: 4vw;
        font-size: 1.8vw;
    }
    .right-second {
        margin-bottom: 1vw;
    }
    .artist-description {
        font-size: 2vw;
    }
    .link-back {
        position: unset;
    }
    .link-book {
        position: unset;
    }
    .left-second {
        position: unset;
    }
}

@media screen and (max-width: 600px) {
    .talent-picture-container {
        height: 100vh;
        z-index: 15000;
    }
    .artists-maxi-container {
        width: 100%;
        min-height: 92.3vh;
        display: flex;
        justify-content: center;
        align-items: center;
        background-image: linear-gradient(rgb(34, 60, 74), rgb(255, 40, 255));
        margin-top: 0;
    }

    .artists {
        position: relative;
        flex-direction: column;
        width: 100vw;
        height: 100%;
        top: 0;
        left: 0;
        /* z-index: 15000; */

        padding: 0;
        margin: 0;
        border: none;
        border-radius: 0;
        box-shadow: none;
        align-items: center;
        justify-content: center;
    }

    .artist-name {
        margin-top: 4vw;
        font-size: 15vw;
        color: rgb(61, 158, 180);
        text-align: center;
        text-shadow: rgb(246 235 19) 2px 2px;
        -webkit-text-stroke: 0px rgb(63, 34, 98);
    }

    .left-first {
        border: none;
        border-radius: 0;
        margin-top: 0;
        width: 100%;
        height: 50vw;
        overflow: hidden;
    }

    .artist-image {
        width: 100%;
        border: none;
        border-radius: 0;
        margin-top: 2vw;
        filter: none;
        position: relative;
        top: 6vw;
        display: none;
    }

    .artist-image-mobile {
        display: block;
        width: 100%;
        border: none;
        border-radius: 0;
        margin-top: 2vw;
        filter: none;
        position: relative;
        top: 6vw;
    }

    .left,
    .right {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    #back,
    #book,
    .fa-chevron-circle-left,
    .fa-arrow-circle-right {
        font-size: 1.6em;
    }

    .book-button-div {
        width: 34vw;
        height: 10vw;
        font-size: 3.5vw;
        border-radius: 10vw;
    }
    .back-button {
        height: 6vw;
        font-size: 3.5vw;
    }
    .left-second {
        margin-top: 3vw;
        display: none;
    }
    .left-third {
        /* width: 200px; */
        margin-top: 3vw;
        display: flex;
        /* justify-items: flex-end; */
        /* align-items: center; */
    }
    .artist-performance {
        color: rgb(236, 197, 212);
        padding: 0 5vw;
        align-self: flex-start;
        margin-top: 10vw;
    }

    .artist-description {
        text-align: justify;
        font-family: "Source Sans Pro", sans-serif;
        font-size: 5vw;
        color: rgb(236, 197, 212);
        padding: 0 5vw;

        margin: 0;
    }

    .right-first {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .right-second {
        width: 90%;
        justify-content: space-between;
    }

    .artist-maxi-more-container {
        margin-top: 5vw;
        display: flex;
        flex-direction: column;
    }
    .artist-maxi-more {
        display: block;
        font-weight: 700;
        font-style: italic;
        color: rgb(63, 34, 98);
        text-align: center;
    }
    .link-back {
        position: unset;
    }
    .link-book {
        position: unset;
    }
}
