#superuser-container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

#superuser-welcome {
    font-size: 3rem;
    font-family: "Source Sans Pro", sans-serif;
    color: white;
    -webkit-text-stroke: 1px #49337e;
    text-align: center;
}

#superuser-form {
    width: 60%;
    background-color: white;
    border: 2px solid #49337e;
    border-radius: 30px;
    padding: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.superuser-form-part {
    /* display: flex; */
    /* border: 1px solid black; */
    /* margin: 0 10px;
    justify-content: center;
    align-items: center;
    height: 50px; */
    width: 100%;
    display: flex;
    align-items: center;
    margin: 6px;
}

.superuser-label-div {
    width: 50%;
}

.superuser-input-div {
    width: 50%;
}

.superuser-form-label {
    width: 90%;
    display: flex;
    justify-content: end;
    font-size: 1.4rem;
    font-family: "Source Sans Pro", sans-serif;
    color: #49337e;
}

.superuser-form-input {
    width: 85%;
    height: 30px;
    font-size: 0.85rem;
    border-radius: 30px;
    outline: none;
    padding: 0 6px;
    border: 2px solid #49337e;
    color: #49337e;
    font-family: "Source Sans Pro", sans-serif;
    padding: 0 15px;
}

.superuser-submit {
    display: flex;
    justify-content: center;
    margin-top: 15px;
}

.superuser-submit-button {
    width: 100px;
    height: 30px;
    border-radius: 30px;
    border: 2px solid #49337e;
    background-color: white;
    color: #49337e;
    font-weight: bold;
    font-family: "Source Sans Pro", sans-serif;
    outline: none;
    cursor: pointer;
}

@media screen and (max-width: 1200px) and (min-width: 601px) {
    #superuser-form {
        flex-direction: column;
        width: 90%;
    }

    #superuser-welcome {
        font-size: 2.5rem;
    }
    .superuser-form-label {
        font-size: 1rem;
    }
}

@media screen and (max-width: 600px) {
    #superuser-container {
        background-color: #6680dd;
    }
    #superuser-form {
        flex-direction: column;
        width: 85%;
    }

    #superuser-welcome {
        font-size: 1.5rem;
    }
    .superuser-form-label {
        font-size: 0.8rem;
    }
}
