.single-feed-container {
    display: flex;
    width: 100%;
    border: 2px solid #49337e;
    border-radius: 20px;
    margin-bottom: 20px;
    padding: 5px 0;
    background-color: white;
    color: #49337e;
    min-height: 30px;
}

.single-feed-container:hover {
    background-color: #49337e;
    color: white;
    transform: scale(1.1);
    transition: all;
    transition-duration: 0.6s;
}

.single-feed-icon {
    font-size: 1.6em;
    /* margin: 15px; */
    cursor: pointer;
}

.single-feed-icon:hover {
    transform: scale(1.2);
    transition: all;
    transition-duration: 0.6s;
}

.single-feed-icon-div {
    width: 15%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.single-feed-name-div {
    width: 40%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
}

.single-feed-name-p {
    font-size: 1em;
    margin: 0;
    font-family: "Source Sans Pro", sans-serif;
    word-break: break-all;
    font-weight: bold;
}

.single-feed-name-p-date {
    font-size: 0.8em;
}

.single-feed-image-div {
    width: 30%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.single-feed-image {
    width: 90%;
    /* height: 80%; */
    border-radius: 15px;
}

.single-feed-icon-set {
    width: 30%;
    display: flex;
    justify-content: space-around;
    align-items: center;
}

@media screen and (max-width: 1200px) and (min-width: 601px) {
    .single-feed-container {
        width: 90%;
    }
}

@media screen and (max-width: 600px) {
    .single-feed-container {
        width: 90%;
    }
    .single-feed-name-p {
        font-size: 0.85rem;
    }
}
