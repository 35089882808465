.menu-container {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    z-index: 1000;
}

.link {
    text-decoration: none;
}

@media screen and (max-width: 1200px) and (min-width: 601px) {
}

@media screen and (max-width: 600px) {
    .menu-container {
        flex-direction: column;
        height: 100%;
        justify-content: flex-start;
    }
}
