.menu-button-regular {
    background-color: #3b9db3;
    border: 1px solid #49337e;
    margin: 4px;
    width: 12vw;
    height: 60px;
    border-radius: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all;
    transition-duration: 0.4s;
}

.menu-button-circular {
    background-color: #3b9db3;
    border: 1px solid #49337e;
    margin: 4px;
    width: 14vw;
    height: 14vw;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all;
    overflow: hidden;
}

.transform-up {
    transform: translateY(-1vw);
    transition: all;
    transition-duration: 0.4s;
}

.menu-button-regular-inner {
    background-image: linear-gradient(
        217deg,
        rgb(144, 69, 254),
        rgb(42, 247, 206),
        rgb(144, 69, 254)
    );
    border: 1px solid #49337e;
    width: 11vw;
    height: 50px;
    border-radius: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: rgb(63 34 98) -2px 2px 5px inset;
}
.menu-button-circular-inner-first {
    border: 1px solid #49337e;
    width: 13.2vw;
    height: 13.2vw;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: linear-gradient(
        217deg,
        rgb(246, 235, 19),
        rgb(252, 178, 2)
    );
    border: 1px solid rgb(63, 34, 98);
    box-shadow: rgb(63 34 98) -2px -2px 5px inset;
    transition-duration: 0.6s;
    position: relative;
    overflow: hidden;
}
.menu-button-circular-inner-second {
    background-image: linear-gradient(
        217deg,
        rgb(42, 247, 206),
        rgb(246, 235, 19),
        rgb(255, 40, 255)
    );
    box-shadow: rgb(63 34 98) 0px 0px 5px 2;
    border: 1px solid #49337e;
    width: 12vw;
    height: 12vw;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all;
    transition-duration: 0.6s;
}
.menu-button-circular-inner-third {
    background-image: linear-gradient(
        217deg,
        rgb(144, 69, 254),
        rgb(42, 247, 206),
        rgb(144, 69, 254)
    );
    border: 1px solid #49337e;
    width: 11vw;
    height: 11vw;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: rgb(63 34 98) -2px 2px 5px inset;
}

.zoom-in {
    transform: scale(1.2);
    transition: all;
    transition-duration: 0.6s;
}

.button-text {
    font-size: 2vw;
    font-family: "Anarchie-Italic", cursive;
    color: rgb(136, 184, 192);
    -webkit-text-stroke: 1px rgb(63, 34, 98);
    text-shadow: rgb(252 178 2) 2px 2px 0px;
    text-decoration: none;
}

.menu-mobile-toggler {
    display: none;
}

.button-text-active {
    color: yellow;
    text-shadow: purple 2px 2px 0px;
}

@media screen and (max-width: 1200px) and (min-width: 601px) {
    .menu-button-regular {
        width: 18vw;
    }
    .menu-button-regular-inner {
        width: 16vw;
    }
    .button-text {
        font-size: 2vw;
    }
    .menu-mobile-toggler {
        display: none;
    }
    .transform-up {
        transform: translateY(-1.5vw);
        transition: all;
        transition-duration: 0.4s;
    }
}

@media screen and (max-width: 600px) {
    .menu-container {
        flex-direction: column;
        height: 65vh;
        z-index: 15;
    }

    /* Buttons styling for mobile menu: */

    .menu-button-regular {
        background-color: transparent;
        border: none;
        width: 40vw;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 4vw;
    }
    .menu-button-regular-inner {
        background-image: none;
        border: none;
        width: 40vw;
        display: flex;
        justify-content: center;
        align-items: center;
        box-shadow: none;
    }
    .button-text {
        font-size: 10vw;
    }
    .menu-notmobile {
        display: none;
    }
    .menu-mobile-toggler {
        display: block;
    }

    .menu-button-mobile-circle {
        background-image: linear-gradient(
            217deg,
            rgb(42, 247, 206),
            rgb(246, 235, 19),
            rgb(255, 40, 255)
        );
        border: 1px solid rgb(63, 34, 98);
        box-shadow: rgb(63 34 98) -2px -2px 5px inset;
        margin: 4px;
        width: 60vw;
        height: 90px;
        border-radius: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: all;
        transition-duration: 0.4s;
        z-index: 15;
    }

    .menu-button-mobile-circle-inner {
        background-image: linear-gradient(
            217deg,
            rgb(144, 69, 254),
            rgb(42, 247, 206),
            rgb(144, 69, 254)
        );
        border: 1px solid #49337e;
        width: 58vw;
        height: 80px;
        border-radius: 46px;
        display: flex;
        justify-content: center;
        align-items: center;
        box-shadow: rgb(63 34 98) -2px 2px 5px inset;
    }

    .menu-first {
        order: -1;
        align-self: flex-start;
    }

    /* Buttons styling for mobile menu: */
}
