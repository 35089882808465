.contact-line {
    margin-bottom: 2vw;
    position: relative;
    display: flex;
    flex-direction: row-reverse;
    border: 1px dashed black;
    width: 20em;
    height: 2em;
    border-radius: 1em;
    align-items: center;
    padding: 0 0.2em;
    background-image: linear-gradient(
        147deg,
        rgb(246, 235, 19),
        rgb(255, 40, 255)
    );
    box-shadow: rgb(63 34 98) 2px 2px 10px 2px;
}

.contact-input {
    text-align: end;
    width: 60%;
    padding: 0 1.2em;
    height: 70%;
    border: none;
    outline: none;
    border: 1px dashed black;
    border-radius: 1.4em;
    font-family: "Arturito";
    text-align: left;
    font-size: 1rem;

    right: 0.3em;
    background-image: linear-gradient(
        90deg,
        rgba(238, 130, 238, 0),
        pink,
        rgba(238, 130, 238, 0)
    );
    transition: all;
    transition-duration: 0.6s;
}

.contact-input:hover {
    padding-left: 7em;
    width: 100%;
}

.contact-label {
    position: absolute;
    z-index: 2;
    left: 1em;
    font-family: "Anarchie-Italic", cursive;
    text-shadow: rgb(42 247 206) 1px 1px 0px;
}

@media screen and (max-width: 1200px) and (min-width: 601px) {
    .contact-line {
        width: 14em;
    }
}

@media screen and (max-width: 600px) {
    .contact-line {
        width: 78vw;
        margin-bottom: 6vw;
    }

    .contact-input {
        width: 45%;
        height: 80%;
    }
    .contact-label {
        font-size: 5vw;
        left: 4vw;
    }
}
