body {
    box-sizing: border-box;
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
        "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
        "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overflow-x: hidden;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
        monospace;
}

@font-face {
    font-family: "Anarchie-Italic";
    src: local("Anarchie-Italic"),
        url(./fonts/Anarchie-Italic.otf) format("truetype");
}
@font-face {
    font-family: "THICCCBOI-ExtraBold";
    src: local("THICCCBOI-ExtraBold"),
        url(./fonts/THICCCBOI-ExtraBold.ttf) format("truetype");
}
@font-face {
    font-family: "Arturito";
    src: local("Arturito"), url(./fonts/Arturito.ttf) format("truetype");
}
