.feed-container {
    width: 100%;
    min-height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 2.3vw;
    z-index: 5;
    transform: translateX(-100%);
    opacity: 0;
    transition: all;
    transition-duration: 0.6s;
}

.feed-container-entered {
    transform: translateX(0);
    opacity: 1;
}

#load-more-button {
    border: none;
    background: none;
    font-size: 2vw;
    font-family: "Anarchie-Italic", cursive;
    color: rgb(136, 184, 192);
    -webkit-text-stroke: 1px rgb(63, 34, 98);
    text-shadow: rgb(252 178 2) 2px 2px 0px;
    text-decoration: none;
    margin-bottom: 30px;
    cursor: pointer;
}

#load-more-button:hover {
    color: yellow;
}

/* Transition effects: */
.fade {
    transition: all;
    transition-duration: 0.6s;
    opacity: 0;
}

.fade-entering {
    transform: scale(1);
    opacity: 1;
    transition: all;
    transition-duration: 0.6s;
}
.fade-entered {
    opacity: 1;
    transform: scale(1);
    transition: all;
}
.fade-exiting {
    transform: scale(4.4);
    transition: all;
    transition-duration: 0.6s;
    opacity: 0;
}
.fade-exited {
    transform: scale(4.4);
    transition: all;
    opacity: 0;
    visibility: hidden;
    transition-duration: 0.6s;
    z-index: -5;
}

.feed-maxi-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 85vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: radial-gradient(
        rgb(236, 197, 212),
        rgb(61, 158, 180),
        rgb(144, 69, 254)
    );
    z-index: 50;
}

.feed-maxi-image-div {
    max-width: 60vw;
    max-height: 60vw;
    display: flex;
    justify-content: center;
    align-items: center;
}

.feed-maxi-image {
    position: relative;
    max-height: 35vw;
    cursor: pointer;
}

.feed-maxi-x {
    align-self: flex-start;
    padding-left: 1vw;
    font-size: 3vw;
    color: rgb(63, 34, 98);
    transition: all;
    transition-duration: 0.6s;
    cursor: pointer;
}

.feed-maxi-x:hover {
    color: yellow;
}

@media screen and (max-width: 1200px) and (min-width: 601px) {
    #load-more-button {
        font-size: 4vw;
    }

    .feed-maxi-container {
        height: 100vh;
    }
}

@media screen and (max-width: 600px) {
    #load-more-button {
        font-size: 6vw;
    }

    .feed-container {
        margin-top: 0;
        padding-top: 6vw;
    }

    .feed-background {
        width: 100%;
        min-height: 100vh;
        background-color: #f07767;
    }
    .feed-maxi-container {
        height: 100vh;
        z-index: 15000;
    }
}
