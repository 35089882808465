#panel-container {
    width: 100%;
    min-height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

#panel-content {
    /* border: 1px solid black; */
    width: 100%;
    padding: 20px 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.panel-menu {
    font-size: 3rem;
    font-family: "Press Start 2P", cursive;
    color: white;
    -webkit-text-stroke: 1px #49337e;
    text-align: center;
}

.panel-back-arrow {
    margin: 10px 0;
    color: white;
    -webkit-text-stroke: 2px #49337e;
    font-size: 3em;
}

.panel-back-arrow:hover {
    -webkit-text-stroke: 2px white;
    color: #49337e;
    transform: scale(1.2);
    transition: all;
    transition-duration: 0.6s;
}

#panel-logout {
    margin: 15px 0;
    font-size: 2rem;
    font-family: "Press Start 2P", cursive;
    color: white;
    -webkit-text-stroke: 2px #49337e;
    text-align: center;
}

#panel-logout:hover {
    color: #49337e;
    -webkit-text-stroke: 2px white;
    transform: scale(1.1);
    transition: all;
    transition-duration: 0.6s;
}

.panel-headline {
    margin: 40px 0;
    font-size: 2rem;
    font-family: "Press Start 2P", cursive;
    color: white;
    -webkit-text-stroke: 1px #49337e;
    text-align: center;
}

.panel-headline:hover {
    color: #49337e;
    -webkit-text-stroke: 1px white;
    transform: scale(1.1);
    transition: all;
    transition-duration: 0.6s;
}

.panel-line {
    margin-bottom: 2em;
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: center;
    border: 1px dashed black;
    width: 20em;
    height: 2em;
    border-radius: 1em;
    align-items: center;
    padding: 0 0.2em;
    background-image: linear-gradient(
        147deg,
        rgb(246, 235, 19),
        rgb(255, 40, 255)
    );
    box-shadow: rgb(63 34 98) 2px 2px 10px 2px;
    padding: 3px;
    cursor: pointer;
}

.panel-label {
    /* position: absolute; */
    text-align: center;
    z-index: 2;
    font-family: "Anarchie-Italic", cursive;
    font-size: 2vw;
    text-shadow: rgb(42 247 206) 1px 1px 0px;
}

.panel-back-button {
    margin: 2vw 0;
    cursor: pointer;
}

@media screen and (max-width: 1200px) and (min-width: 601px) {
    .panel-line {
        width: 14em;
    }
    .panel-label {
        font-size: 3vw;
    }
}

@media screen and (max-width: 600px) {
    #panel-container {
        background-color: #6680dd;
    }
    .panel-line {
        width: 60vw;
    }
    .panel-label {
        font-size: 4vw;
    }
}
